// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import {ref, computed, watch} from '@vue/composition-api'
import store from '@/store'

export default function useUnityEditorVersionApi() {
    // Use toast
    const toast = useToast()
    // Table Handlers
    const refDataListTable = ref(null)
    const perPage = ref(localStorage.getItem('unityEditorVersionPerPage') ?? 10)
    const totalData = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 15, 20, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const isLoading = ref(true)
    const unityVersionList = ref(null)
    const tableColumns = [
        {key: 'id', sortable: true},
        {key: 'version', sortable: true},
        {key: 'servers', sortable:false},
        {key: 'created_at', sortable: true},
        {key: 'actions', sortable: false},
    ]

    const dataMeta = computed(() => {
        const localItemsCount = refDataListTable.value ? refDataListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalData.value,
        }
    })

    const refetchData = () => {
        localStorage.setItem('unityEditorVersionPerPage', perPage.value)
        refDataListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery], () => {
        refetchData()
    })


    // ------------------------------------------------
    // Get Unity Editor Version List
    // ------------------------------------------------
    const fetchData = (ctx, callback) => {
        let sort = isSortDirDesc.value ? `-${sortBy.value}` : sortBy.value
        store.dispatch('unityVersionEditorStore/fetchUnityVersionEditors', {
            sort: sort,
            page: currentPage.value,
            perPage: perPage.value,
            q: searchQuery.value,
            pagination: true
        }).then(response => {
            const {data} = response.data
            totalData.value = response.data.pagination.total
            callback(data)
        }).catch(error => {
            console.log(error)
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: 'Oops! Something went wrong!',
                },
            })
        })
    }

    const fetchDataVersions = (ctx, callback) => {
        store.dispatch('unityVersionEditorStore/fetchUnityVersionEditors', { 
            pagination: false
        }).then(response => {
            const {data} = response.data
            unityVersionList.value = data.map(item=>({id:item.id,label:item.version}))
        }).catch(error => {
            console.log(error)
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: 'Oops! Something went wrong!',
                },
            })
        })
    }

    return {
        fetchData,
        tableColumns,
        perPage,
        currentPage,
        totalData,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refDataListTable,
        isLoading,
        refetchData,
        fetchDataVersions,
        unityVersionList
    }
}
