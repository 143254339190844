<template>
    <b-sidebar id="create-unity-editor-version-sidebar" :visible="isCreateUnityEditorVersionSidebarActive" bg-variant="white"
        sidebar-class="sidebar-lg" shadow backdrop no-header right
        @change="(val) => $emit('update:is-create-unity-editor-version-sidebar-active', val)">
        <template #default="{ hide }">
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">New Unity Editor Version</h5>
                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>
            <validation-observer v-slot="{ handleSubmit }" ref="refFormObserver">
                <b-form ref="form" class="p-2" @submit.prevent="handleSubmit(onSubmit)">
                    <validation-provider #default="{ errors }" name="Version" rules="required">
                        <b-form-group label="Version" label-for="version">
                            <b-form-input id="version" v-model="fetchData.version" autofocus trim
                                :state="errors.length > 0 ? false:null" placeholder="Version" />
                        </b-form-group>
                    </validation-provider>
                    <b-form-group v-if="servers" label="Servers" label-for="server">
                        <v-select id="server" v-model="fetchData.servers" :reduce="(option) => option.id"
                            :options="servers" label="label" placeholder="Select Server" class="w-100" multiple>
                        </v-select>
                    </b-form-group>
                    <div class="d-flex mt-2">
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2"
                            type="submit">
                            Add
                        </b-button>
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
                            @click="hide">
                            Cancel
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>
import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required} from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from "@core/comp-functions/forms/form-validation";

export default {
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BFormInvalidFeedback,
        BButton,
        vSelect,
        ToastificationContent,
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    model: {
        prop: 'isCreateUnityEditorVersionSidebarActive',
        event: 'update:is-create-unity-editor-version-sidebar-active',
    },
    props: {
        isCreateUnityEditorVersionSidebarActive: {
            type: Boolean,
            required: true,
        },
        storeType: {},
        servers:{},
    },
    methods: {
        onSubmit() {
            this.$store.dispatch('unityVersionEditorStore/addUnityVersionEditor', this.fetchData).then(() => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Successfully added!',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                });
            }).then(() => {
                this.$emit('refresh-data')
                this.$emit('update:is-create-unity-editor-version-sidebar-active', false)
                this.fetchData = {}

            }).catch((errors) => {
                console.log(errors)
            })
        }
    },
    data() {
        return {
            fetchData: {},
            required,
        }
    },
    setup() {
        const {
            refFormObserver,
            getValidationState,
        } = formValidation()

        return {
            refFormObserver,
            getValidationState,
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
