<template>
    <div>
        <unity-editor-version-create :is-create-unity-editor-version-sidebar-active.sync="isCreateUnityEditorVersionSidebarActive" :servers="serverList" @refresh-data="refetchData"/>
        <unity-editor-version-edit :is-edit-unity-editor-version-sidebar-active.sync="isEditUnityEditorVersionSidebarActive" :editData="editData" :servers="serverList"  @refresh-data="refetchData"/>
        <b-card no-body class="mb-0">
            <div class="m-2">
                <b-row>
                    <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 ml-1" />
                    </b-col>
                    <b-col cols="12" md="8">
                        <b-row class="d-flex align-items-center justify-content-end">
                            <b-col cols="12" md="3">
                                <b-form-input v-model="searchQuery" class="d-inline-block mr-1"
                                    placeholder="Search..." />
                            </b-col>
                            <b-col class="mb-md-0 mb-2" cols="12 " md="3" v-if="$can('read', 'buildServerCreate')">
								<b-button class="w-100" variant="primary" @click="isCreateUnityEditorVersionSidebarActive = true">
									<span class="text-nowrap">New Editor Version</span>
								</b-button>
							</b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
            <b-table ref="refDataListTable" class="position-relative" :items="fetchData" responsive
                :table-class="'build-list-table table dataTable no-footer dtr-column'" striped :fields="tableColumns"
                primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc">
                <template #table-busy>
                    <div class="text-center my-2">
                        <b-spinner class="d-block mx-auto mb-50"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>

                <template #cell(id)="data">
                    <span class="align-text-top text-capitalize">{{ data.item.id }}</span>
                </template>
                <template #cell(version)="data">
                    <span class="align-text-top text-capitalize">{{ data.item.version }}</span>
                </template>
                <template #cell(servers)="data">
                    <b-badge pill class="text-capitalize mr-50" v-for="item in filterServers(data.item.servers) " :key="item.id">
        				{{ item.label }}
					</b-badge>
                </template>
                <template #cell(created_at)="data">
                    <span class="align-text-top text-capitalize">{{ dateFormat(data.item.created_at) }}</span>
                </template>
                <template #cell(actions)="data">
                    <b-link class="mr-1"
                            v-if="($can('read', 'buildServerEdit'))"
                            @click="fncEditSidebar(data.item.id)"
                            v-b-tooltip.hover.top="'Edit'">
                        <feather-icon icon="EditIcon" size="18"/>
                    </b-link>

                    <b-link class="mr-1"
                            v-if="($can('read', 'buildServerDelete'))"
                            v-b-tooltip.hover.top="'Delete'"
                            v-on:click="confirmDelete(data.item.id)">

                        <feather-icon icon="TrashIcon" size="18"/>
                    </b-link>
                </template>

            </b-table>
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
                            entries</span>
                    </b-col>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalData" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>

        </b-card>
    </div>
</template>

<script>
import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import vSelect from "vue-select";
import useUnityEditorVersionApi from "@/composables/useUnityEditorVersionApi";
import UnityEditorVersionCreate from "@/views/unityEditorVersion/UnityEditorVersionCreate";
import UnityEditorVersionEdit from "@/views/unityEditorVersion/UnityEditorVersionEdit";
import {ref} from "@vue/composition-api";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useBuildServerApi from "@/composables/useBuildServerApi";

export default {
    components: {
        BSpinner,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        vSelect,
        UnityEditorVersionCreate,
        UnityEditorVersionEdit
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    data() {
        return {
            loading: false,
        }
    },
    setup() {
        const isCreateUnityEditorVersionSidebarActive = ref(false)
        const isEditUnityEditorVersionSidebarActive = ref(false)
        const editData = ref(false)
        const {
            fetchData,
            refDataListTable,
            tableColumns,
            perPage,
            currentPage,
            totalData,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refetchData,
        } = useUnityEditorVersionApi()

        const {
            fetchDataServer,
            serverList
        } = useBuildServerApi()
        return {
            fetchData,
            refDataListTable,
            tableColumns,
            perPage,
            currentPage,
            totalData,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refetchData,
            isCreateUnityEditorVersionSidebarActive,
            isEditUnityEditorVersionSidebarActive,
            editData,
            fetchDataServer,
            serverList
        }
    },
    methods: {
        filterServers(list){
            if(this.serverList && list)return this.serverList.filter(item => list.includes(item.id));
        },
        fncEditSidebar(id, type) {
            this.isEditUnityEditorVersionSidebarActive = true;
            this.editData = {}
            this.$store.dispatch('unityVersionEditorStore/fetchUnityVersionEditor', {id: id}).then((response) => {
                const {data} = response.data
                this.editData = data
            })
        },
        dateFormat(date) {
            return moment(date).format('YYYY-MM-DD HH:mm')
        },
        confirmDelete(id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "Are you sure you want to delete the version?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete!',
                customClass: {
                    confirmButton: 'btn btn-primary mr-1',
                    cancelButton: 'btn btn-outline-primary ms-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                        this.$store.dispatch('unityVersionEditorStore/deleteUnityVersionEditor', {
                            id: id
                        }).then(() => {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Successfully deleted!',
                                    icon: 'CheckIcon',
                                    variant: 'success',
                                },
                            })
                        }).then(() => {
                            this.refetchData()
                        })
                }
            })
        }
    },mounted(){
        this.fetchDataServer()
    }
}

</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
